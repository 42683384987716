// @flow
import { injectGlobal } from 'styled-components'
import { fontFace } from './typography'
import { theme } from './theme'

injectGlobal`
  ${fontFace}

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    max-width: 100%;
    width: 100%;
  }

  body {
    box-sizing: border-box;
    background-color: #181818;
    font-size: 100%;
    font-family: 'Gordita', sans-serif;
    font-feature-settings: 'ss02';
    color: ${theme.baseColor};
    line-height: 1;
  }

  a {
    text-decoration: none;
    color: ${theme.baseColor};
    outline: none;
  }
`