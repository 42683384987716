// @flow
import * as React from 'react'
import styled from 'styled-components'
import { flex, space } from 'styled-system'

import Flex from 'src/components/Flex'
import Container from 'src/components/Container'
import Icon from 'src/components/Icon'

export const Header = styled.header`
  display: flex;
`;

const HeaderContent = styled.div`
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vw;
  position: relative;
  ${flex}
`;

const Logo = styled.div`
  display: inline-flex;
`

export default () => {
  return (
    <Header bg='black'>
      <Container width='100%'>
        <HeaderContent flex='1'>
          <Logo p={3}>
            <Icon glyph='logo' size={60} />
          </Logo>
        </HeaderContent>
      </Container>
    </Header>
  )
}