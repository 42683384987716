// @flow
import * as React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div``
const ErrorTitle = styled.div``
const ErrorSubtitle = styled.div``
const ErrorLink = styled.a``

class ErrorBoundary extends React.Component {
  state = { error: false }
  
  render() {
    if (this.state.error) {
      return (
        <ErrorContainer>
          <ErrorTitle>Uh oh! Something went wrong. 😟</ErrorTitle>
          <ErrorSubtitle>We'll fix it soon, we promise.</ErrorSubtitle>
          <ErrorLink to='/'>Go Back</ErrorLink>
        </ErrorContainer>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary