// @flow
import styled from 'styled-components'
import {
  space,
  height,
  width,
  maxWidth,
  flex,
  color
} from 'styled-system'

export const Section = styled.section`
  ${space}
  ${height}
  ${width}
  ${maxWidth}
  ${flex}
  ${color}
`

export const Default = styled(Section)`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.black};
  position: relative;
`