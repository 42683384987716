// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import { color } from 'styled-system'

export const SvgInline = styled.svg`
  height: 100%;
  width: 100%;
  position: relative;
  color: inherit;
  fill: currentColor;
  ${color};
`

export const SvgContainer = styled.div`
  display: inline-block;
  flex: ${props => props.size ? `0 0 ${props.size}px` : '0 0 32px'};
  width: ${props => props.size ? `${props.size}px` : '32px'};
  max-width: ${props => props.size ? `${props.size}px` : '32px'};
  height: ${props => props.size ? `${props.size}px` : `32px`};
  position: relative;
  color: inherit;
`