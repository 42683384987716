// @flow
import * as React from 'react'
import styled from 'styled-components'
import { flex, color, space } from 'styled-system'

import Flex from 'src/components/Flex'
import Container from 'src/components/Container'
import Text from 'src/components/Text'
import Link from 'src/components/Link'

export const Footer = styled.footer`
  display: flex;
  ${color}
`;

const FooterContent = styled.div`
  padding: 5vw;
  position: relative;
  ${flex}
`;

const FooterLinks = styled.div`
  ${space}
`

export default () => {
  return (
    <Footer bg='black'>
      <Container width='100%'>
        <FooterContent flex='1'>
          <Flex
            flexDirection='column'
            alignItems='flex-start'
            flex='1'
          >
            <Text.p>Storm Foundry — Forging your ideas.</Text.p>
            <FooterLinks mt={3}>
              <Link mr={4} href={`mailto:hello@stormfoundry.co`}>Contact Us</Link>
              <Link mr={5} href={`https://twitter.com/stormfoundry`}>Twitter</Link>
            </FooterLinks>
          </Flex>
        </FooterContent>
      </Container>
    </Footer>
  )
}