// @flow
const vars = {
  baseFontSize: '18px',
  baseAccentColor: '#ed722f',
  baseColor: '#fff'
}

const colors = {
  white: '#fff',
  black: '#181818',
  darkGrey: '#1a1a1a',
  lightGrey: '#b3b3b3',
  primary: '#ed722f',
  secondary: '#43bf4d',
  selection: '#ffd700',
  base: '#fff',
  text: '#fff'
}

const maxWidths = ['50em', '64em', '90em']
const borderWidths = [0, 1, 2, 3]
const breakpoints = ['32em', '46em', '64em']
const fontSizes = ['1rem', '1.375rem', '2.5rem', '4rem']
const lineHeights = {
  sm: 1,
  md: 1.25,
  lg: 2
}

const fontWeights = {
  normal: 300,
  semibold: 600,
  bold: 700
}

const letterSpacings = {
  default: 'normal',
  caps: '0.125em',
  spaced: '0.25em'
}

export const theme = {
  ...vars,
  colors,
  borderWidths,
  breakpoints,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  maxWidths
}