// @flow
import styled from 'styled-components'
import {
  color,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  letterSpacing,
  space
} from 'styled-system'

const Text = styled.div`
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${textAlign}
  ${letterSpacing}
  ${space}
`

Text.defaultProps = {
  fontSize: '1rem',
  fontWeight: 300,
  letterSpacing: '1px',
  lineHeight: 1.75
}

Text.p = Text.withComponent('p')
Text.meta = Text.withComponent('span')

Text.displayName = 'Text'

export default Text