// @flow
import styled from 'styled-components'
import {
  space,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  textAlign,
  color
} from 'styled-system'

const Link = styled.a`
  border-bottom: 1px solid;
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${textAlign}
  ${letterSpacing}
  ${space}
`

Link.defaultProps = {
  fontSize: '1rem',
  lineHeight: 1.5,
  letterSpacing: '1px',
  fontWeight: 300
}

Link.displayName = 'Link'

export default Link