// @flow
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';

type Props = {
  title?: string,
  description?: string,
  keywords?: string,
  image?: string,
  children?: any,
};

export default class Head extends PureComponent<Props> {
  render() {
    const { title, description, keywords, image, siteUrl, children } = this.props;

    return (
      <Helmet title={`${title} — Forging your ideas.`}>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={siteUrl} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@stormfoundry" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {children}
      </Helmet>
    )
  }
}
