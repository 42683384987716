// @flow
import * as React from 'react'
import * as PropTypes from 'prop-types'
import { Default } from './style'

export default (props: Object) => {
  switch (props.theme) {
    default:
      return (
        <Default {...props}>
          {props.children}
        </Default>
      )
  }
}