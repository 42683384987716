// @flow
import * as React from 'react'
import { StaticQuery } from 'gatsby'
import styled, { ThemeProvider } from 'styled-components'
import { theme } from 'src/styles/theme'
import 'src/styles/reset.css.js'

import ErrorBoundary from 'src/components/ErrorBoundary'
import Head from 'src/components/Head'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'

const Body = styled.div`
  width: 100%;
`

class Layout extends React.Component {
  render() {
    const { children } = this.props
    const isLoaded = children

    return (
      <StaticQuery
        query={graphql`
          query TemplateQuery {
            site {
              siteMetadata {
                title
                description
                keywords
                siteUrl
              }
            }
          }
        `}
        render={data => (
          <ThemeProvider theme={theme}>
            <ErrorBoundary fallback={null}>
              <Head {...data.site.siteMetadata} />

              {isLoaded ? (
                <Body>
                  <Header />
                  {children}
                  <Footer />
                </Body>
              ) : (
                <Body />
              )}
            </ErrorBoundary>
          </ThemeProvider>
        )}
      />
    )
  }
}

export default Layout