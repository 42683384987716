import * as React from 'react'
import styled, { css } from 'styled-components'
import { SvgInline, SvgContainer } from './style'

type Props = {
  glyph: string,
  size?: number | string,
  onClick?: Function
}

type GlyphProps = {
  glyph: string
}

export const Glyph = ({ glyph }: GlyphProps) => {
  switch (glyph) {
    case 'plus':
      return (
        <g>
          <path d="M16,6c5.1,0 7.247,0.575 8.336,1.664c1.089,1.089 1.664,3.236 1.664,8.336c0,5.1 -0.575,7.247 -1.664,8.336c-1.089,1.089 -3.236,1.664 -8.336,1.664c-5.1,0 -7.247,-0.575 -8.336,-1.664c-1.089,-1.089 -1.664,-3.236 -1.664,-8.336c0,-5.1 0.575,-7.247 1.664,-8.336c1.089,-1.089 3.236,-1.664 8.336,-1.664Zm0,-2c10,0 12,2 12,12c0,10 -2,12 -12,12c-10,0 -12,-2 -12,-12c0,-10 2,-12 12,-12Zm-1,8c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1l0,3l3,0c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1l-3,0l0,3c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1l0,-3l-3,0c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1l3,0l0,-3Z" />
        </g>
      )
    case 'logo':
      return (
        <g>
          <path d="M21.51 20.368c.374.293.44.834.147 1.209l-7.718 9.855c-.29.371-.708.568-1.14.568-.245 0-.496-.064-.73-.196-.644-.364-.91-1.1-.647-1.793l3.542-9.313h-8.5c-.529 0-1.002-.274-1.266-.732-.264-.458-.264-1.005.001-1.462l2.933-5.067c.238-.411.765-.552 1.177-.314.411.239.551.765.313 1.177l-2.707 4.676h8.426c.48 0 .93.236 1.203.632s.334.9.163 1.35L13.64 29.02l6.662-8.506c.293-.374.834-.44 1.208-.147zm4.737-8.484c.564 0 1.067.314 1.314.821.247.507.184 1.098-.163 1.542l-1.954 2.494c-.17.216-.422.33-.678.33-.186 0-.373-.06-.53-.183-.374-.294-.44-.835-.147-1.209l1.624-2.074h-9.15c-.42 0-.821-.181-1.099-.498-.277-.316-.405-.737-.35-1.154l1.212-9.233-3.616 6.247c-.238.411-.765.552-1.177.314-.411-.239-.551-.765-.313-1.177L15.488.732c.354-.611 1.067-.88 1.735-.652.669.227 1.071.875.98 1.574l-1.344 10.23h9.388z" />
        </g>
      )
    case 'arrow':
      return (
        <g>
          <polyline fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="3.5,6.5 8,11 12.5,6.5 " />
        </g>
      )
    default:
      return (
        <g>
          <path d="M16,6c5.1,0 7.247,0.575 8.336,1.664c1.089,1.089 1.664,3.236 1.664,8.336c0,5.1 -0.575,7.247 -1.664,8.336c-1.089,1.089 -3.236,1.664 -8.336,1.664c-5.1,0 -7.247,-0.575 -8.336,-1.664c-1.089,-1.089 -1.664,-3.236 -1.664,-8.336c0,-5.1 0.575,-7.247 1.664,-8.336c1.089,-1.089 3.236,-1.664 8.336,-1.664Zm0,-2c10,0 12,2 12,12c0,10 -2,12 -12,12c-10,0 -12,-2 -12,-12c0,-10 2,-12 12,-12Zm-1,8c0,-0.552 0.448,-1 1,-1c0.552,0 1,0.448 1,1l0,3l3,0c0.552,0 1,0.448 1,1c0,0.552 -0.448,1 -1,1l-3,0l0,3c0,0.552 -0.448,1 -1,1c-0.552,0 -1,-0.448 -1,-1l0,-3l-3,0c-0.552,0 -1,-0.448 -1,-1c0,-0.552 0.448,-1 1,-1l3,0l0,-3Z" />
        </g>
      )
  }
}

class Icon extends React.Component<Props> {
  render() {
    const {
      glyph,
      size = 32,
      onClick
    } = this.props

    return (
      <SvgContainer
        size={size}
        onClick={onClick}
      >
        <SvgInline
          id={glyph}
          fillRule="evenodd"
          clipRule="evenodd"
          strokeLineJoin="round"
          strokeMiterlimit="1.414"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 32 32"
          fit
        >
          <title id="title">{glyph}</title>
          <Glyph glyph={glyph} />
        </SvgInline>
      </SvgContainer>
    )
  }
}

export default Icon