// @flow
import styled from 'styled-components'
import {
  width,
  maxWidth,
  color,
  flex,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  space
} from 'styled-system'

const Flex = styled.div`
  display: flex;
  ${width}
  ${maxWidth}
  ${color}
  ${alignItems}
  ${justifyContent}
  ${flexWrap}
  ${flexDirection}
  ${flex}
  ${space}
`

Flex.displayName = 'Flex'

export default Flex