import styled, { css } from 'styled-components'
import * as fonts from 'src/fonts'

export const fontFace = `
  @font-face {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: normal;
    src: url(${fonts.eotGorditaLight});
    src: url(${fonts.eotGorditaLight}) format('embedded-opentype'),
    url(${fonts.woffGorditaLight}) format('woff'),
    url(${fonts.ttfGorditaLight}) format('truetype');
  }

  @font-face {
    font-family: 'Gordita';
    font-style: normal;
    font-weight: bold;
    src: url(${fonts.eotGorditaBold});
    src: url(${fonts.eotGorditaBold}) format('embedded-opentype'),
    url(${fonts.woffGorditaBold}) format('woff'),
    url(${fonts.ttfGorditaBold}) format('truetype');
  }
`