// @flow
import * as React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { flex } from 'styled-system'

import Layout from 'src/components/Layout'
import Head from 'src/components/Head'
import Flex from 'src/components/Flex'
import Container from 'src/components/Container'
import Section from 'src/components/Section'
import Heading from 'src/components/Heading'
import Text from 'src/components/Text'

const HeroContent = styled.div`
  height: 100%;
  padding: 5vw;
  ${flex}
`;

class Index extends React.Component {
  render() {
    const { site } = this.props.data
    const headingSizes = [2, 3];
    const textSizes = [0, 1];

    const meta = {
      title: site.siteMetadata.title,
      keywords: site.siteMetadata.keywords,
      description: site.siteMetadata.description,
      image: `https://stormfoundry.co/og.png`
    }

    return (
      <Layout location={this.props.location}>
        <Head {...meta} />

        <Section theme='black'>
          <Container width='100%'>
            <HeroContent flex='1'>
              <Flex
                maxWidth={760}
                justifyContent='center'
                flexDirection='column'
                flex='1'
              >
                <Heading.h1 fontSize={headingSizes} letterSpacing={'-2px'}>Storm — Foundry. We design and build digital products and platforms that help companies to level up.</Heading.h1>
                <Text.p fontSize={textSizes}>Our purposefully-small remote team of makers, combined with our experience of building great websites, platforms and brands allows us to forge partnerships with companies worldwide.</Text.p>
                <Text.p fontSize={textSizes}>European Gaming League, BFNations, Battlerite and EsportsDeck — these are just some of the companies that we have designed for and worked closely with to develop world-leading tournament platforms and content management tools.</Text.p>
                <Text.p fontSize={textSizes}>We are always looking to deliver game—changing ideas and strategies for new partners that share our vision and drive. If you think we're a good fit, contact us.</Text.p>
              </Flex>
            </HeroContent>
          </Container>
        </Section>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`