// @flow
import styled from 'styled-components'
import {
  space,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  textAlign,
  letterSpacing
} from 'styled-system'

const Heading = styled.div`
  ${color}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${textAlign}
  ${letterSpacing}
  ${space}
`

Heading.defaultProps = {
  fontSize: '1.5rem',
  fontWeight: 700,
  lineHeight: 1.2
}

Heading.h1 = Heading.withComponent('h1')
Heading.h2 = Heading.withComponent('h2')
Heading.h3 = Heading.withComponent('h3')
Heading.h4 = Heading.withComponent('h4')
Heading.h5 = Heading.withComponent('h5')
Heading.h6 = Heading.withComponent('h6')

Heading.displayName = 'Heading'

export default Heading